<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title class="font-weight-bold mb-3 mt-10"
        >تفاصيل القسم</v-card-title
      >
      <v-row v-if="edit_loader">
        <v-col v-for="(a, i) in 3" :key="i" cols="6" md="3">
          <v-skeleton-loader type="card-heading"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-form ref="form" v-if="!edit_loader">
        <v-tabs class="mb-3" v-model="tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="tabs"
            v-for="(lang, i) in $store.getters.langs"
            :key="i"
          >
            {{ lang.lang_name }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->

        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="(lang, i) in $store.getters.langs" :key="i">
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  :rules="[(v) => !!v || 'حقل مطلوب']"
                  v-model="category.category_name[lang.locale]"
                  solo
                >
                  <template slot="label">
                    <strong class="red--text" v-if="tabs == 0">*</strong>
                    اسم القسم
                  </template>
                </v-text-field>
              </v-col>
              <!--/ col  company name-->
              <v-col cols="12" sm="3" v-if="tabs == 0">
                <v-select
                  disabled
                  :rules="[(v) => !!v || 'حقل مطلوب']"
                  :value="category.parent_category"
                  :items="categories"
                  item-value="id"
                  item-text="category_name"
                  solo
                  :loading="loading_cat"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    نوع القسم
                  </template>
                </v-select>
              </v-col>
              <!--/ col category type-->

              <v-col cols="12" sm="3" v-if="tabs == 0">
                <v-text-field
                  :rules="[(v) => v < 100 || 'يجب ان لايتعدي ال 100%']"
                  solo
                  label="التخفيض"
                  type="number"
                  v-model.number="category.sale"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="tabs == 0">
                <v-text-field
                  solo
                  label="الترتيب"
                  type="number"
                  v-model.number="category.sort"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" v-if="tabs == 0">
                <v-file-input
                  v-model="category.image"
                  prepend-icon=""
                  prepend-inner-icon="attach_file"
                  solo
                  label="صورة القسم"
                ></v-file-input>
              </v-col>
            </v-row>
            <!--/ row -->
          </v-tab-item>
        </v-tabs-items>
        <!--/ col image-->
        <v-btn
          :loading="save_loader"
          :disabled="save_loader"
          color="primary"
          class="save"
          width="250"
          height="50"
          @click="save()"
          >حفظ</v-btn
        >
      </v-form>
      <!-- / form -->
    </v-card>
    <!--/ card -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حفظ القسم بنجاح</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_error">
      <p>برجاء مراجعة البيانات</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">cancel</v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "New_category",
  data() {
    return {
      tabs: 0,
      loading_cat: true,
      categories: [],
      edit_loader: false,
      save_loader: false,
      snackbar_success: false,
      snackbar_error: false,
      category: {
        category_name: {},
        parent_category: "main",
        image: null,
        sale: 0,
        srot: 0,
      },
    };
  },
  methods: {
    get_categories() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "Categories",
            method: "get",
          },
        })
        .then((res) => {
          res.data.data.forEach((e) => {
            if (e.id != this.$route.params.id) {
              this.categories.push(e);
            }
          });
          this.categories.unshift({
            category_name: "الرئيسي",
            id: "main",
          });
          this.loading_cat = false;
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.save_loader = true;
        const formData = new FormData();

        Object.entries(this.category).forEach((e) => {
          if (e[0] != "category_name") {
            formData.append(e[0], e[1]);
          } else {
            Object.entries(this.category.category_name).forEach((s) => {
              formData.append(`category_name[${s[0]}]`, s[1]);
            });
          }
        });
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.$route.params.id
                ? `Categories/${this.$route.params.id}`
                : `Categories`,
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.snackbar_success = true;
            this.save_loader = false;
            console.log(res);
            setTimeout(() => {
              this.$router.push("/Categories");
            }, 1500);
          })
          .catch((err) => {
            this.snackbar_error = true;
            this.save_loader = false;
          });
      }
    },
    edit_category() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `Categories/${this.$route.params.id}`,
            method: "get",
          },
        })
        .then((res) => {
          let data = res.data.data;
          this.category.parent_category = data.parent_category
            ? data.parent_category
            : "main";
          this.category.sale = data.sale;
          this.category.sort = data.sort;
          data.category_name.forEach((e) => {
            this.category.category_name[e.locale] = e.name;
          });
          console.log(data);
          this.edit_loader = false;
        });
    },
  },
  mounted() {
    this.get_categories();
    if (this.$route.params.id) {
      this.edit_loader = true;
      this.edit_category();
    }
  },
};
</script>

<style lang="scss" scoped>
.save {
  font-size: 20px;
}
::v-deep {
  .v-skeleton-loader__heading {
    width: 80% !important;
    height: 40px;
  }

  .v-input--switch__thumb {
    width: 30px;
    height: 30px;
    right: -14px;
  }
  .v-input--switch .v-input--selection-controls__input {
    width: 58px;
    height: 29px;
    margin-top: 0 !important;
  }
  .v-input--switch__track {
    width: 200px;
    height: 18px;
    position: absolute;
    opacity: 0.6;
    right: -11px;
    top: calc(50% - 5px);
    border-radius: 50px;
    background-color: #b7ecbf;
  }
  .per {
    .v-input--switch__track {
      width: 110px;
      height: 30px;
      position: absolute;
      opacity: 1;
      right: -11px;
      top: calc(50% - 9px);
      &::after {
        content: "بدون تخفيض";
        float: left;
        margin-left: 12px;
        font-weight: bold;
        font-size: 13px;
        margin-top: 4px;
        color: #000;
        display: block;
      }
      &::before {
        content: " تخفيض";
        color: #000;
        float: right;
        font-weight: bold;
        font-size: 13px;
        margin-right: 12px;
        margin-left: 4px;
        margin-top: 4px;
        display: none;
      }
      &.primary--text {
        &::before {
          display: block !important;
        }
        &::after {
          display: none !important;
        }
      }
    }
  }
  .custom_swtich {
    .v-input--selection-controls__ripple {
      top: calc(50% - 22px) !important;
      right: -27px !important;
      width: 40px;
      height: 40px;
    }

    .v-input--is-label-active {
      .v-input--switch__thumb {
        transform: translate(-90px, 0) !important;
      }
      .v-input--selection-controls__ripple {
        right: 45px !important;
      }
      .v-input--switch__track {
        background-color: #bbebef;
      }
    }
  }
}
</style>
